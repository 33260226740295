import { useRef } from 'react';
import { useNotificationBadgeContext } from '@volvo/vce-package-site-uikit';
import { notificationBadgeIds } from '../types';
import { useGetMaterialMappingBadgeAmount } from './useGetMaterialMappingBadgeAmount';
import { useUnassigedLoadTicketBadgeAmount } from './useUnassignedLoadTicketBadgeAmount';

/*
 * This hook is used to initialize the notification badges for the application.
 * It is called once when the application is loaded.
 */
export const useInitBadges = () => {
  const { setNotificationBadge, notificationBadges } = useNotificationBadgeContext();

  const counts = useRef({
    materialMapping: 0,
    unassignedLoadTickets: 0,
  });

  useGetMaterialMappingBadgeAmount({
    pollIntervalSeconds: 60,
    onCompleted: (amount) => {
      counts.current.materialMapping = amount;

      setNotificationBadge({
        id: notificationBadgeIds.materialMapping,
        content: amount,
      });

      const unassignedLoadTickets = notificationBadges.find(
        ({ id }) => id === notificationBadgeIds.unassignedLoadTickets,
      );

      setNotificationBadge({
        id: notificationBadgeIds.deviations,
        content: amount + counts.current.unassignedLoadTickets,
        viewed: Number(unassignedLoadTickets?.content) === 0 ? true : unassignedLoadTickets?.viewed,
      });
    },
  });

  useUnassigedLoadTicketBadgeAmount({
    pollIntervalSeconds: 60,
    onCompleted: (amount) => {
      counts.current.unassignedLoadTickets = amount;

      setNotificationBadge({
        id: notificationBadgeIds.unassignedLoadTickets,
        content: amount,
      });

      const materialMapping = notificationBadges.find(
        ({ id }) => id === notificationBadgeIds.materialMapping,
      );
      setNotificationBadge({
        id: notificationBadgeIds.deviations,
        content: amount + counts.current.materialMapping,
        viewed: Number(materialMapping?.content) === 0 ? true : materialMapping?.viewed,
      });
    },
  });
};

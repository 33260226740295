import { lazy } from 'react';
import { Outlet, type RouteObject } from 'react-router-dom';
import { SuspenseWrapper } from '@volvo/vce-package-site-applicationlayout';
import { routeFactory } from './routeFactory';

const SiteMapPage = lazy(() => import('@/dev/pages/site-map/SiteMap'));
const ProcessPage = lazy(() => import('@/dev/pages/process/Process'));
const DeviationsPage = lazy(() => import('@/dev/pages/deviations/Deviations'));

export const routes: RouteObject[] = [
  {
    id: 'map-app-root',
    element: (
      <SuspenseWrapper>
        <Outlet />
      </SuspenseWrapper>
    ),
    children: [
      {
        id: 'siteMap',
        path: `${routeFactory.siteMap.overview(':siteId', ':companyId')}/*`,
        element: <SiteMapPage />,
      },
      {
        id: 'process',
        path: routeFactory.process.overview(':siteId', ':companyId'),
        element: <ProcessPage />,
      },
      {
        id: 'deviations',
        path: routeFactory.deviations.overview(':siteId', ':companyId'),
        element: <DeviationsPage />,
        children: [
          {
            path: routeFactory.deviations.unassignedSiteLoadTickets(':siteId', ':companyId'),
            element: <DeviationsPage />,
          },
          {
            path: routeFactory.deviations.materialMapping(':siteId', ':companyId'),
            element: <DeviationsPage />,
          },
        ],
      },
    ],
  },
];

import { useTranslation } from 'react-i18next';
import {
  SITE_COMPANY_LICENSE_PERMISSIONS,
  SITE_COMPANY_LICENSE_RESOURCE,
} from '@volvo/vce-package-permission-policy';
import {
  CurrentView,
  useSiteContext,
  type SideMenuItemType,
} from '@volvo/vce-package-site-applicationlayout';
import { type SystemIconId } from '@volvo/vce-uikit';
import i18n from '@/i18n';
import { routeFactory } from '@/routing';
import { notificationBadgeIds } from '../types';
import { useInitBadges } from './useInitBadges';

export const useMenuItems = (): SideMenuItemType[] => {
  const { t } = useTranslation(['navigation'], { i18n });

  const { selectedSite, selectedCompany } = useSiteContext();

  useInitBadges();

  return [
    {
      key: 'map',
      text: t('navigation:map'),
      icon: 'map' as SystemIconId,
      href: routeFactory.siteMap.overview(selectedSite.id, selectedCompany.id),
      pathPattern: `${routeFactory.siteMap.overview(selectedSite.id, selectedCompany.id)}/*`,
      requirements: {
        view: CurrentView.SITE,
        licencePermissions: [
          `${SITE_COMPANY_LICENSE_RESOURCE.siteMap}:${SITE_COMPANY_LICENSE_PERMISSIONS.siteMap.manage}`,
        ],
      },
    },
    {
      key: 'process',
      text: t('navigation:process'),
      icon: 'factory' as SystemIconId,
      href: routeFactory.process.overview(selectedSite.id, selectedCompany.id),
      pathPattern: `${routeFactory.process.overview(selectedSite.id, selectedCompany.id)}/*`,
      requirements: {
        view: CurrentView.SITE,
        licencePermissions: [
          `${SITE_COMPANY_LICENSE_RESOURCE.siteProcess}:${SITE_COMPANY_LICENSE_PERMISSIONS.siteProcess.manage}`,
        ],
      },
    },
    {
      key: 'deviations',
      text: t('navigation:deviations'),
      icon: 'technical-error' as SystemIconId,
      href: routeFactory.deviations.unassignedSiteLoadTickets(selectedSite.id, selectedCompany.id),
      pathPattern: `${routeFactory.deviations.overview(selectedSite.id, selectedCompany.id)}/*`,
      notificationBadgeId: notificationBadgeIds.deviations,
      requirements: {
        view: CurrentView.SITE,
        licencePermissions: [
          `${SITE_COMPANY_LICENSE_RESOURCE.siteDeviations}:${SITE_COMPANY_LICENSE_PERMISSIONS.siteDeviations.manage}`,
        ],
      },
      parentKey: 'activities',
    },
  ];
};

import { useMemo } from 'react';
import { useSiteContext } from '@volvo/vce-package-site-applicationlayout';
import { useAuth } from '@volvo/vce-uikit';
import { useExternalMaterialMappings } from '@/gql-types/generated-types-super-graph';
import { getApolloClient } from '../../gql/apollo-client';

type Props = {
  onCompleted?: (amount: number) => void;
  pollIntervalSeconds?: number;
};

export const useGetMaterialMappingBadgeAmount = ({
  onCompleted,
  pollIntervalSeconds = 0,
}: Props) => {
  const { selectedCompany } = useSiteContext();
  const { getToken } = useAuth();
  const client = useMemo(() => getApolloClient(getToken), [getToken]);

  const { data } = useExternalMaterialMappings({
    client,
    variables: { companyId: selectedCompany.id },
    pollInterval: pollIntervalSeconds * 1000,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ siteCompanies }) => {
      onCompleted?.(
        siteCompanies.company?.externalMaterialMappings.edges.filter(
          ({ node }) => !node.materialVariant,
        ).length ?? 0,
      );
    },
  });

  return (
    data?.siteCompanies.company?.externalMaterialMappings.edges.filter(
      ({ node }) => !node.materialVariant,
    ).length ?? 0
  );
};

import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useSiteContext } from '@volvo/vce-package-site-applicationlayout';
import { useAuth } from '@volvo/vce-uikit';
import { useQueryUnassignedSiteLoadTickets } from '@/gql-types/generated-types-super-graph';
import { defaultFilters } from '../../federations/deviations/unassigned-load-tickets/defaults';
import { getApolloClient } from '../../gql/apollo-client';

type Props = {
  onCompleted?: (amount: number) => void;
  pollIntervalSeconds?: number;
};

export const useUnassigedLoadTicketBadgeAmount = ({
  onCompleted,
  pollIntervalSeconds = 0,
}: Props) => {
  const { selectedSite } = useSiteContext();
  const { getToken } = useAuth();
  const client = useMemo(() => getApolloClient(getToken), [getToken]);

  const { data } = useQueryUnassignedSiteLoadTickets({
    client,
    variables: {
      from: defaultFilters.startDate,
      to: defaultFilters.endDate,
      filterBy: { flowId: null, siteId: selectedSite.id },
    },
    pollInterval: pollIntervalSeconds * 1000,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ siteLoadTickets }) => {
      onCompleted?.(
        siteLoadTickets.groupBySourceFlowId.groups.filter(
          ({ sourceTicketFlowId }) => !isEmpty(sourceTicketFlowId),
        ).length ?? 0,
      );
    },
  });

  return data?.siteLoadTickets.groupBySourceFlowId.groups.length ?? 0;
};
